import React, { useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';

import Input from '../Input';
import Button from '../Button';
import { FormattedMessage } from 'react-intl';
import Label from '../Label/Label';
import { FelaComponent } from 'react-fela';

import style from './RemoveAccount.styles';
import Heading1 from '../Heading1';
import Text from '../Text';
import LocalizedLink from '../LocalizedLink';
import { LinkType } from '../Link/Link.style';

const fields = {
    email: 'email',
};

const validationSchema = Yup.object().shape({
    [fields.email]: Yup.string()
        .email()
        .required(true),
});

const initialValues = {
    [fields.email]: '',
};

export const RemoveAccount = () => {
    const [successText, setSuccessText] = useState(undefined);

    function handleSubmit(_, { resetForm }) {
        setSuccessText('removeAccount.form.successText');

        resetForm();
    }

    return (
        <FelaComponent style={style}>
            {({ className }) => (
                <div className={className}>
                    <Heading1>
                        <FormattedMessage id="removeAccount.title" />
                    </Heading1>
                    <Text>
                        <FormattedMessage
                            id="removeAccount.text"
                            values={{
                                a: (...chunks) => (
                                    <LocalizedLink
                                        forceLocale="cs"
                                        type={LinkType.NATIVE}
                                        target="_blank"
                                        to={'/privacyPolicy'}
                                    >
                                        {chunks}
                                    </LocalizedLink>
                                ),
                            }}
                        />
                    </Text>
                    <Formik onSubmit={handleSubmit} validationSchema={validationSchema} initialValues={initialValues}>
                        {({ values, touched, errors, handleChange, handleBlur, handleSubmit }) => {
                            const errorsAndTouches = Object.keys(fields).reduce((acc, key) => {
                                acc[key] = Boolean(touched[key] && errors[key]);
                                return acc;
                            }, {});

                            return (
                                <form onSubmit={handleSubmit}>
                                    <Label key={'label'} htmlFor={fields.email}>
                                        <FormattedMessage id="removeAccount.form.email" />
                                    </Label>
                                    <Input
                                        type="input"
                                        id={fields.email}
                                        value={values.email}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        hasError={errorsAndTouches.email}
                                    />
                                    <Button type="submit">
                                        <FormattedMessage id="removeAccount.form.submit" />
                                    </Button>
                                </form>
                            );
                        }}
                    </Formik>
                    {successText && (
                        <Text color="greyDarker">
                            <FormattedMessage id={successText} />
                        </Text>
                    )}
                </div>
            )}
        </FelaComponent>
    );
};

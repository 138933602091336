import SEO from '../components/SEO/SEO';
import withPageContext from '../pageContext';
import React from 'react';
import { RemoveAccount } from '../components/RemoveAccount';

const RemoveAccountPage = () => (
    <>
        <SEO title="title.index" desc="description.index" />
        <RemoveAccount />
    </>
);

export default withPageContext(RemoveAccountPage);

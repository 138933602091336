export default () => ({
    overflow: 'hidden',

    maxWidth: 1200,

    padding: '3rem 1.5rem 1rem',
    margin: '0 auto',
    minHeight: '100vh',

    '& h1': {
        padding: 0,
        marginBottom: '1rem',
    },

    '& p': {
        padding: 0,
        marginBottom: '1rem',
    },

    '& form': {
        marginBottom: '1rem',
    },
});
